import React from 'react';

const HomeSocialLink = ({ Icon, color, onMouseEnter, onMouseLeave, link }) => {
  return (
    <a
      href={link}
      className='home__social-link'
      target='_blank'
      rel='noopener noreferrer'
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Icon fill={color} />
    </a>
  );
};

export default HomeSocialLink;
