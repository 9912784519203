import React, { useMemo, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import throttle from 'lodash.throttle';
import classnames from 'classnames';

import './home.css';

import Bio from '../components/bio';
import Header from '../components/header';
import Footer from '../components/footer';
import SEO from '../components/seo';
import Logo from '../png/logo.png';
import LinkedinIcon from '../svg/iconmonstr-linkedin.svg';
import TwitterIcon from '../svg/iconmonstr-twitter.svg';
import GithubIcon from '../svg/iconmonstr-github.svg';
import HomeSocialLink from '../components/home-social-link';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

const Home = ({ data, location }) => {
  const [ isMouseSupported, setMouseSupported ] = useState(true);
  const [ mouseX, setMouseX ] = useState(0);
  const [ mouseY, setMouseY ] = useState(0);
  const [ changedTargetWidth, setChangedTargetWidth ] = useState('initial');
  const [ changedTargetHeight, setChangedTargetHeight ] = useState('initial');
  const [ changedTargetX, setChangedTargetX ] = useState('initial');
  const [ changedTargetY, setChangedTargetY ] = useState('initial');
  const [ changedTargetRadius, setChangedTargetRadius ] = useState('initial');
  const [ targetPinned, setTargetPinned ] = useState(false);

  useEffect(() => {
    setMouseSupported(matchMedia('(pointer:fine)').matches);
  }, []);

  const updateCursor = useMemo(() => {
    const throttledUpdateCursor = throttle((e) => {
      setMouseX(e.pageX);
      setMouseY(e.pageY);
      // document.documentElement.style.setProperty('--home-target-x', e.pageX);
      // document.documentElement.style.setProperty('--home-target-y', e.pageY);
    }, 150);

    return (e) => {
      if (!isMouseSupported) {
        return;
      }
      e.persist();
      return throttledUpdateCursor(e);
    }
  }, [ isMouseSupported ]);

  const changeTargetToButtonShape = (e) => {
    if (!isMouseSupported) {
      return;
    }
    const rect = e.target.getBoundingClientRect();
    setChangedTargetHeight(rect.height);
    setChangedTargetWidth(rect.width);
    setChangedTargetX(rect.left + (rect.width / 2));
    setChangedTargetY(rect.top + (rect.height / 2));
    setChangedTargetRadius(getComputedStyle(e.target).borderRadius);
    setTargetPinned(true);
  };

  const resetTargetShape = () => {
    if (!isMouseSupported) {
      return;
    }
    setChangedTargetHeight('initial');
    setChangedTargetWidth('initial');
    setChangedTargetX('initial');
    setChangedTargetY('initial');
    setChangedTargetRadius('initial');
    setTargetPinned(false);
  }

  const socialLinks = [
    {
      Icon: LinkedinIcon,
      color: 'var(--primary)',
      link: 'https://www.linkedin.com/in/imolorhe',
    },
    {
      Icon: TwitterIcon,
      color: 'var(--primary)',
      link: 'https://twitter.com/imolorhe',
    },
    {
      Icon: GithubIcon,
      color: 'var(--primary)',
      link: 'https://github.com/imolorhe',
    },
  ];

  return (
    <div
      className='home-page'
      role='main'
      onMouseMove={updateCursor}
      style={{
        '--home-target-x': mouseX,
        '--home-target-y': mouseY,
        '--home-changed-target-x': changedTargetX,
        '--home-changed-target-y': changedTargetY,
        '--home-changed-target-width': changedTargetWidth,
        '--home-changed-target-height': changedTargetHeight,
        '--home-changed-target-radius': changedTargetRadius,
      }}
    >
      <SEO title="Home" />
      <div
        className={classnames('home__target home__target--dot', { 'home__target--pinned': targetPinned })}
      ></div>
      <div
        className={classnames({'home__target home__target--larger': true, 'home__target--pinned': targetPinned })}
      ></div>
      <div className='home__main-content'>
        <div className="home__logo-wrapper">
          <img
            src={Logo}
            style={{
              height: 'auto',
              margin: 0,
            }}
            />
        </div>
        <div className='home__social-links'>
          {socialLinks.map((socialLink, i) => (
            <HomeSocialLink
              key={i}
              onMouseEnter={changeTargetToButtonShape}
              onMouseLeave={resetTargetShape}
              Icon={socialLink.Icon}
              link={socialLink.link}
              color={socialLink.color}
            />
          ))}
        </div>
        <div className='home__blog-link'>
          <AniLink
            paintDrip
            to="/blog"
            hex='#FF662D'
            onMouseEnter={changeTargetToButtonShape}
            onMouseLeave={resetTargetShape}
          >
            Checkout my blog ›
          </AniLink>
        </div>
      </div>
    </div>
  )
}

export default Home;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
